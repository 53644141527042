const VALUES = {
    sideBarWidthExpand: '220px',

    // colorPrimary: 'white',
    // colorBgPrimary: '#3D4977',
    colorPrimary: '#AFBDD1',
    colorBgPrimary: '#343c4b',

    colorSecondary: '#1c1c73',
    colorBgSecondary: '#FFF',

    colorAlternative: 'white',
    colorBgAlternative: '#E5E5E5',

    colorSidebarSelected: '#454e5f',
};

export default VALUES;
