import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import clsx from 'clsx';
import {
    Drawer,
    List,
    Typography,
    Divider,
    ListItem,
    ListItemIcon,
    ListItemText,
    Icon,
    Paper,
    makeStyles,
} from '@material-ui/core';
import {
    Dashboard as DashboardIcon,
    Fastfood as FastFoodIcon,
    Category as CategoryIcon,
    List as ListIcon,
    Group as GroupIcon,
    Face as FaceIcon,
    Call as CallIcon,
    LocalMall as CampaignIcon,
    Message as MessageIcon,
} from '@material-ui/icons';
import logoSvg from '../../../assets/images/logo.png';
import dashboard from "../../../assets/images/dashboard.png";
import fastFood from "../../../assets/images/s1.png";
import category from "../../../assets/images/s2.png";
import group from "../../../assets/images/s4.png";
import list from "../../../assets/images/s3.png";
import history from "../../../assets/images/history.jpg";
import face from "../../../assets/images/s5.png";
import call from "../../../assets/images/s6.png";
import campagin from "../../../assets/images/s7.png";
import sms from "../../../assets/images/s8.png";
import survey from "../../../assets/images/survey.png";
import { useTranslation } from 'react-i18next';

import styleConstants from '../../../constants/styleConstants';
import "./Sidebar.css"

// MENU LIST
const menuList = [
    'products',
    'categories',
    'orders',
    'history',
    'users',
    // 'customers',
    // 'calls',
    // 'campaign',
    // 'sms',
    "survey",
    "modification",
    "classification",
    "branch",
    "notification"
];

// const menuListIcons = [
//     <FastFoodIcon />,
//     <CategoryIcon />,
//     <ListIcon />,
//     <GroupIcon />,
//     <FaceIcon />,
//     <CallIcon />,
//     <CampaignIcon />,
//     <MessageIcon />,
// ];

// STYLES
const drawerWidth = styleConstants.sideBarWidthExpand;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    },
    paper: {
        height: '100%',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    selectedItem: {
        background: styleConstants.colorSidebarSelected,
        '&:hover': {
            background: styleConstants.colorSidebarSelected,
            // background: "#215467",
        },
    },
    icon: {
        height: '45px',
        width: '90%',
    },
    logoImg: {
        height: '100%',
        width: '100%',
    },
    link: {
        textDecoration: 'none',
        color: styleConstants.colorPrimary,
    },
    customIcon: {
        height: "2rem",
        width: "2rem",
        borderRadius: "50%",
        objectFit: "cover",
    },

    // CUSTOMIZATION
    colorPrimary: {
        background: styleConstants.colorBgPrimary,
    },

    colorSecondary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary,
    },
    colorBgAlternative: {
        background: styleConstants.colorBgAlternative,
    },
}));

//

export default function Sidebar(props) {
    const classes = useStyles();
    const { t } = useTranslation();
    const { url } = useRouteMatch();
    const path = window.location.pathname.substr(1);

    const menuListIcons = [
        <img src={fastFood} alt="fast food" className={classes.customIcon}/>,
        <img src={category} alt="category" className={classes.customIcon}/>,
        <img src={list} alt="list" className={classes.customIcon}/>,
        <img src={history} alt="history" className={classes.customIcon}/>,
        <img src={group} alt="group" className={classes.customIcon}/>,
        <img src={face} alt="face" className={classes.customIcon}/>,
        <img src={call} alt="call" className={classes.customIcon}/>,
        <img src={campagin} alt="campagin" className={classes.customIcon}/>,
        <img src={sms} alt="sms" className={classes.customIcon}/>,
        <img src={survey} alt="survey" className={classes.customIcon}/>,
        <img src={survey} alt="moderation" className={classes.customIcon}/>,
        <img src={survey} alt="classification" className={classes.customIcon}/>,
        <img src={survey} alt="branch" className={classes.customIcon}/>,
        <img src={survey} alt="notification" className={classes.customIcon}/>,
    ];

    return (
        <Drawer
            variant="permanent"
            className={clsx(classes.drawer, classes.colorSecondary, {
                [classes.drawerOpen]: props.open,
                [classes.drawerClose]: !props.open,
            })}
            classes={{
                paper: clsx({
                    [classes.drawerOpen]: props.open,
                    [classes.drawerClose]: !props.open,
                }),
            }}
        >
            <Paper className={clsx(classes.paper, classes.colorSecondary)}>
                <div className={classes.toolbar}>
                    <Icon className={clsx(classes.icon, classes.colorSecondary)}>
                        <img className={classes.logoImg} src={logoSvg} alt="logo" />
                    </Icon>
                </div>

                <Divider />
                <List>
                    <Link className={clsx(classes.link, classes.colorSecondary)} to={`${url}`}>
                        <ListItem button>
                            <ListItemIcon
                                // className={classes.colorSecondary}
                            >
                                <img src={dashboard} alt="dash" height={32}/>
                            </ListItemIcon>
                            <ListItemText primary={t(`sidebar.dashboard`)} />
                        </ListItem>
                    </Link>
                </List>

                <Divider />
                <List>
                    {menuList.map((item, i) => (
                        <Link
                            className={clsx(classes.link, classes.colorSecondary)}
                            to={`/${item}`}
                            key={item}
                        >
                            <ListItem
                                button
                                className={clsx(path === item && classes.selectedItem)}
                                key={item}
                            >
                                <ListItemIcon
                                    // className={classes.colorSecondary}
                                >
                                    {menuListIcons[i]}
                                </ListItemIcon>
                                <ListItemText primary={t(`sidebar.${item}`)} />
                            </ListItem>
                        </Link>
                    ))}
                </List>
                <Divider />
            </Paper>
        </Drawer>
    );
}
