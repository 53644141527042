import { combineReducers } from 'redux';
import userReducer from './userReducer';
import orderReducer from './orderReducer';
import ordersReducer from './OrdersReducer';
import kanbanReducer from './KanbanReducer';

const allReducers = combineReducers({
    currentUser: userReducer,
    // orderBasket: orderReducer,
    orders: ordersReducer,
    kanban: kanbanReducer,
});

export default allReducers;
