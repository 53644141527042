import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    TextField,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    makeStyles,
} from '@material-ui/core';

import { campaignPropList as propList } from '../../../../constants/propListConstants';
import styleConstants from '../../../../constants/styleConstants';

//
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    //
    colorPrimary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary,
    },
}));

//

export default function UsersForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        label={t('campaignProps.name')}
                        type="tel"
                        value={props.input['name'] || ''}
                        onChange={props.handleTextChange('name')}
                        margin="dense"
                    />
                </Grid>

                <Grid item sm={5}>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink>{t('campaignProps.status')}</InputLabel>
                        <Select
                            required
                            value={props.input['status'] || ''}
                            onChange={props.handleTextChange('status')}
                            className={classes.selectEmpty}
                        >
                            {propList[1].enum.map((el) => (
                                <MenuItem value={el} key={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
