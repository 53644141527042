import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';

import { Button, Menu, MenuItem, makeStyles, Hidden } from '@material-ui/core';
import { Language as TranslateIcon } from '@material-ui/icons';

import { setLang as setLangCookie } from '../../../utils/Cookies';
import langList from '../../../language/langList';
import styleConstants from '../../../constants/styleConstants';

//
const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: '15px',
        [theme.breakpoints.down('xs')]: {
            width: '30px !important',
        },
    },
    button: {
        padding: '4px',
    },

    //
    colorPrimary: {
        color: styleConstants.colorPrimary,
        colorBgPrimary: styleConstants.colorBgPrimary,
    },
}));

//

function LangSwitcher(props) {
    const [lang, setLang] = useState();
    const [anchorEl, setAnchorEl] = useState(null);

    const classes = useStyles();

    useEffect(() => {
        const { label } = langList.filter((el) => el.value === props.i18n.language.slice(0, 2))[0];
        setLang(label);
    }, [props.i18n.language]);

    function handleClick(event) {
        setAnchorEl(event.currentTarget);
    }
    function handleClose() {
        setAnchorEl(null);
    }
    function handleChange(element) {
        return function () {
            props.i18n.changeLanguage(element.value);
            setLangCookie(element.value);
            handleClose();
        };
    }

    return (
        <div className={classes.root}>
            <Button
                className={clsx(classes.button, classes.colorPrimary)}
                aria-haspopup="true"
                startIcon={<TranslateIcon />}
                onClick={handleClick}
            >
                <Hidden xsDown>{lang}</Hidden>
            </Button>

            <Menu
                id="lang-switcher-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {langList.map((el) => (
                    <MenuItem key={el.value} onClick={handleChange(el)}>
                        {el.label}
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
}

export default withTranslation()(LangSwitcher);
