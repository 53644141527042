import Cookies from 'universal-cookie';
const cookies = new Cookies();

const config = {
    jwt: {
        maxAge: 24 * 60 * 60,
    },
    session: {
        // session ends 5 minuts before token expire time
        maxAge: 24 * 60 * 60 - 5 * 60,
    },
    refreshToken: {
        maxAge: 7 * 24 * 60 * 60,
    },
};

export function getAll() {
    return cookies.getAll();
}
export function get(name) {
    return cookies.get(name);
}

export function authUser(data) {
    cookies.set('jwt', data.jwt, config.jwt);
    cookies.set('session', true, config.session);
    cookies.set('refreshToken', data.refreshToken, config.refreshToken);
}
export function refreshUser(data) {
    cookies.set('jwt', data.jwt, config.jwt);
    cookies.set('session', true, config.session);
}

export function logout() {
    cookies.remove('jwt');
    cookies.remove('session');
    cookies.remove('refreshToken');
}

export function setLang(lang) {
    cookies.set('lang', lang);
}

export function getLang() {
    return cookies.get('lang');
}