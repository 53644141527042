const columns = {
    call: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'phone_number', label: 'Phone Number', numeric: false, disablePadding: false },
        { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
        { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
        // { id: 'type', label: 'Type', numeric: false, disablePadding: false },
        // { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        { id: 'orders', label: 'Orders', type: "button", numeric: false, disablePadding: false },
        // { id: 'customer', label: 'Customer', numeric: false, disablePadding: false },
    ],

    customer: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
        { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
        { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
        { id: 'email', label: 'Email', numeric: false, disablePadding: false },
    ],
    branch: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Name', numeric: false, disablePadding: false },
        { id: 'branch_Type', label: 'Branch type', numeric: false, disablePadding: false },
        { id: 'address', label: 'Adress', numeric: false, disablePadding: false },
        { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
        { id: 'lat', label: 'Latitude', numeric: false, disablePadding: false },
        { id: 'lng', label: 'Longitude',  numeric: false, disablePadding: false },
    ],

    order: [
        // { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        // { id: 'shipping_address', label: 'Adress', numeric: false, disablePadding: false },
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'order_type', label: 'Order Type', numeric: false, disablePadding: false },
        { id: 'subtotal', label: 'Total Cost', numeric: true, disablePadding: false },
    ],

    history: [
        { id: 'main_order_id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'created_date', label: 'Created Date', numeric: true, disablePadding: false },
        { id: 'phoneNumber', label: 'Phone', numeric: false, disablePadding: false },
        { id: 'order_type', label: 'Order Type', numeric: false, disablePadding: false },
        { id: 'payment_method', label: 'Payment Method', numeric: false, disablePadding: false },
        { id: 'subtotal', label: 'Total Cost', numeric: true, disablePadding: false },
    ],

    product: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Name', numeric: false, disablePadding: false },
        { id: 'price', label: 'Price', numeric: false, disablePadding: false },
        { id: 'retail_price', label: 'Retail Price', numeric: false, disablePadding: false },
    ],

    user: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'first_name', label: 'First Name', numeric: false, disablePadding: false },
        { id: 'last_name', label: 'Last Name', numeric: false, disablePadding: false },
        { id: 'phone', label: 'Phone', numeric: false, disablePadding: false },
        { id: 'role', label: 'Role', numeric: false, disablePadding: false },
        { id: 'select', label: 'Select', type: 'button', numeric: false, disablePadding: false },
    ],

    userHistory: [
        { id: 'id', label: 'Order Id', numeric: true, disablePadding: false },
        { id: 'created_date', label: 'Created Date', numeric: false, disablePadding: false },
        { id: 'subtotal', label: 'Subtotal', numeric: false, disablePadding: false },
        { id: 'shipping_address_txt', label: 'Address', numeric: false, disablePadding: false },
        { id: 'branch_id', label: 'Branch', numeric: false, disablePadding: false },
        { id: 'select', label: 'Select', type: 'button', numeric: false, disablePadding: false },
    ],

    sms: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'phone_number', label: 'Phone Number', numeric: true, disablePadding: false },
        { id: 'text', label: 'Text', numeric: false, disablePadding: false },
        { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        { id: 'assign', label: 'Assign', numeric: true, disablePadding: false },
        { id: 'user', label: 'User', numeric: true, disablePadding: false },
    ],

    category: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Name', numeric: false, disablePadding: false },
        { id: 'order_in_list', label: 'Order in List', numeric: true, disablePadding: false },
        { id: 'description', label: 'Description', numeric: false, disablePadding: false },
    ],

    campaign: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Name', numeric: false, disablePadding: false },
        { id: 'status', label: 'Status', numeric: false, disablePadding: false },
    ],
    tariff: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Name', numeric: false, disablePadding: false },
        { id: 'min_price', label: 'Price', numeric: false, disablePadding: false },
        { id: 'select', label: '', numeric: false, type: "button", disablePadding: false },
    ],
    survey: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'title_uz', label: 'Savollar', numeric: false, disablePadding: false },
        // { id: 'min_price', label: 'Price', numeric: false, disablePadding: false },
        // { id: 'select', label: '', numeric: false, type: "button", disablePadding: false },
    ],
    modification: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'name', label: 'Savollar', numeric: false, disablePadding: false },
        { id: 'created_date', label: 'Yaratilgan sanasi', numeric: false, disablePadding: false },
        // { id: 'select', label: '', numeric: false, type: "button", disablePadding: false },
    ],
    notification: [
        { id: 'id', label: 'ID', numeric: true, disablePadding: false },
        { id: 'send_time', label: 'Send time', numeric: false, disablePadding: false },
        { id: 'text', label: 'Text', numeric: false, disablePadding: false },
        { id: 'status', label: 'Status', numeric: false, disablePadding: false },
        // { id: 'select', label: '', numeric: false, type: "button", disablePadding: false },
    ],
};

export default function translateColumn(name, translate) {
    return columns[name].map((el, i) => {
        // Skipping translation if 'ID'
        if (i > 0) return { ...el, label: translate(`${name}Props.${el.id}`) };
        else return el;
    });
}
