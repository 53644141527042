import Axios from 'axios';
import * as cookies from './Cookies';

//
// const baseURL = 'https://sevimli-api.thinkland.uz/api/';
const baseURL = 'https://sevimli-lavash.uz/api/';
const url = {
    me: 'me/',
    auth: 'token/',
    authRefresh: 'token/refresh/',
    dashboard: 'dashboard/',
    products: 'products/',
    categories: 'category/',
    orders: 'orders/',
    ordersHistory: 'postorders/',
    ordersHistoryTotal: 'postorders//total_sum/',
    statusOrders: 'statusorder/',
    users: 'users/',
    delivery_tariffs: 'delivery_tariffs/',
    customers: 'customers/',
    calls: 'calls/',
    campaigns: 'campaigns/',
    documents: 'document/',
    branches: 'branches/',
    survey:'questions/',
    sms: 'sms/',
    mod:'modifications/',
    branch:'branches/',
    classification:'classification/',
};

const axios = Axios.create({
    baseURL,
    validateStatus: function(status) {
        return status >= 200 && status <= 503;
    }
});

axios.interceptors.request.use(function(config) {
    const token = cookies.get('jwt');

    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

// AUTH
export function login(data) {
    return axios.post(url.auth, data);
}

export function refreshToken(data) {
    return axios.post(url.authRefresh, data);
}

export function getMe() {
    return axios.get(url.me);
}

export function updateMe(data) {
    return axios.put(url.me, data);
}

// DASHBOARD
export function dashboardGet(page = 1) {
    return axios.get(`${url.dashboard}?page=${page}`);
}
//MODERATION
export function moderationGet(page = 1, pageSize = 20) {
    return axios.get(`${url.mod}?page=${page}&page_size=${pageSize}`);
}
//MODERATION
export function branchGet(page = 1, pageSize = 20) {
    return axios.get(`${url.branch}?page=${page}&page_size=${pageSize}`);
}
// PRODUCTS
export function productGet(page = 1, pageSize = 20) {
    return axios.get(`${url.products}?page=${page}&page_size=${pageSize}`);
}

export function productGetByCategoryId(categoryId, page = 1, pageSize = 20, is_active = true) {
    return axios.get(`${url.products}?page=${page}&page_size=${pageSize}&category=${categoryId}&is_active=${true}`);
}
export function classGetByCategoryId(parentId, page = 1, pageSize = 20, is_active = true) {
    return axios.get(`${url.classification}?page=${page}&page_size=${pageSize}&parent=${parentId}&is_active=${true}`);
}

export function productGetAll(id) {
    return axios.get(`${url.products}?category=${id}`);
}

export function productGetById(id) {
    return axios.get(`${url.products}${id}/`);
}

export function productPost(data) {
    return axios.post(url.products, data);
}
export function modificationPost(data) {
    return axios.post(url.mod, data);
}

export function productPut(id, data) {
    return axios.put(`${url.products}${id}/`, data);
}
export function modificationPut(id, data) {
    return axios.put(`${url.mod}${id}/`, data);
}

export function productDelete(id, data) {
    return axios.delete(`${url.products}${id}/`, { data });
}
export function moderationDelete(id, data) {
    return axios.delete(`${url.mod}${id}/`, { data });
}
export function branchDelete(id, data) {
    return null
}

// ORDERS
export function orderGet(page = 1, pageSize = 30) {
    return axios.get(`${url.orders}?page=${page}&page_size=${pageSize}`);
}

export function orderGetById(id) {
    return axios.get(`${url.orders}${id}/`);
}

export function orderPost(data) {
    return axios.post(url.orders, data);
}

export function orderPut(id, data) {
    return axios.put(`${url.orders}${id}/`, data);
}

export function orderUpdateStatus(id, data) {
    return axios.post(`${url.orders}${id}/change_status/`, data);
}

export function orderDelete(id, data) {
    return axios.delete(`${url.orders}${id}/`, { data });
}

// ORDERS HISTORY
export function orderHistoryGet(page = 1, pageSize = 30, params) {
    return axios.get(`${url.ordersHistory}?page=${page}&page_size=${pageSize}`, { params: params });
}
export function orderHistoryTotalGet(page = 1, pageSize = 20, params) {
    return axios.get(`${url.ordersHistoryTotal}?page=${page}&page_size=${pageSize}`, { params: params });
}

// STATUS ORDERS
export function statusOrderGet(page = 1, pageSize = 30) {
    return axios.get(`${url.statusOrders}?page=${page}&page_size=${pageSize}`);
}

export function statusOrderGetById(id) {
    return axios.get(`${url.statusOrders}${id}/`);
}

export function statusOrderPost(data) {
    return axios.post(url.statusOrders, data);
}

export function statusOrderPut(id, data) {
    return axios.put(`${url.statusOrders}${id}/`, data);
}

export function statusOrderDelete(id, data) {
    return axios.delete(`${url.statusOrders}${id}/`, { data });
}

export function surveyGet(page = 1, pageSize = 30) {
    return axios.get(`${url.survey}`);
}
// export function surveyGet(page = 1, pageSize = 30) {
//     return axios.get(`${url.survey}?page=${page}&page_size=${pageSize}`);
// }
// export function surveyGet(page = 1, pageSize = 30) {
//     return axios.get(`${url.survey}?page=${page}&page_size=${pageSize}`);
// }

// CATEGORIES
export function categoryGet(page = 1, pageSize =30) {
    return axios.get(`${url.categories}?page=${page}&page_size=${pageSize}`);
}
// CLASSIFICATION
export function classGet(parent, page = 1, pageSize =30) {
    return axios.get(`${url.classification}?parent=${parent}`);
}

export function categoryGetById(id) {
    return axios.get(`${url.categories}${id}/`);
}

export function categoryPost(data) {
    return axios.post(url.categories, data);
}
export function classificationPost(data) {
    return axios.post(url.classification, data);
}

export function categoryPut(id, data) {
    return axios.put(`${url.categories}${id}/`, data);
}
export function classificationPut(id, data) {
    return axios.put(`${url.classification}${id}/`, data);
}

export function categoryDelete(id, data) {
    return axios.delete(`${url.categories}${id}/`, { data });
}
export function classificationDelete(id, data) {
    return axios.delete(`${url.classification}${id}/`, { data });
}

// USERS
export function userGet(page = 1, pageSize = 20, search) {
    return axios.get(`${url.users}?page=${page}&page_size=${pageSize}`,{params: search});
}

export function courierGet(page = 1, pageSize = 20) {
    return axios.get(`${url.users}?page=${page}&page_size=${pageSize}&type=courier`);
}

export function tariffGet(page = 1, pageSize = 20) {
    return axios.get(`${url.delivery_tariffs}?page=${page}&page_size=${pageSize}`);
}

export function userGetById(id) {
    return axios.get(`${url.users}${id}/`);
}

export function userPost(data) {
    return axios.post(url.users, data);
}

export function userPut(id, data) {
    return axios.put(`${url.users}${id}/`, data);
}

export function userDelete(id) {
    return axios.delete(`${url.users}${id}/`);
}

// CUSTOMERS
export function customerGet(page = 1, pageSize = 20) {
    return axios.get(`${url.customers}?page=${page}&page_size=${pageSize}`);
}

export function customerGetById(id) {
    return axios.get(`${url.customers}${id}/`);
}

export function customerPost(data) {
    return axios.post(url.customers, data);
}

export function customerPut(id, data) {
    return axios.put(`${url.customers}${id}/`, data);
}

export function customerDelete(id) {
    return axios.delete(`${url.customers}${id}/`);
}

// CALSS
export function callGet(page = 1, pageSize = 20) {
    return axios.get(`${url.calls}?page=${page}&page_size=${pageSize}`);
}

export function callGetById(id) {
    return axios.get(`${url.calls}${id}/`);
}

export function callPost(data) {
    return axios.post(url.calls, data);
}

export function callPut(id, data) {
    return axios.put(`${url.calls}${id}/`, data);
}

export function callDelete(id) {
    return axios.delete(`${url.calls}${id}/`);
}
export function surveyDelete(id) {
    return axios.delete(`${url.survey}${id}/`);
}

// CAMPAIGN
export function campaignGet(page = 1, pageSize = 20) {
    return axios.get(`${url.campaigns}?page=${page}&page_size=${pageSize}`);
}

export function campaignGetById(id) {
    return axios.get(`${url.campaigns}${id}/`);
}

export function campaignPost(data) {
    return axios.post(url.campaigns, data);
}

export function campaignPut(id, data) {
    return axios.put(`${url.campaigns}${id}/`, data);
}

export function campaignDelete(id) {
    return axios.delete(`${url.campaigns}${id}/`);
}

// DOCUMENT
export function documentGet(page = 1, pageSize = 20) {
    return axios.get(`${url.documents}?page=${page}&page_size=${pageSize}`);
}

export function documentGetById(id) {
    return axios.get(`${url.documents}${id}/`);
}

export function documentPost(data) {
    return axios.post(url.documents, data);
}
export function classPost(data) {
    return axios.post(url.classification, data);
}

export function documentPut(id, data) {
    return axios.put(`${url.documents}${id}/`, data);
}

export function documentDelete(id) {
    return axios.delete(`${url.documents}${id}/`);
}

export function getBranches() {
    return axios.get(`${url.branches}`);
}

// SMS
export function smsGet(page = 1, pageSize = 20) {
    return axios.get(`${url.sms}?page=${page}&page_size=${pageSize}`);
}

export function smsPost(data) {
    return axios.post(url.sms, data);
}
