import React,{useState} from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
// import Table from '../../components/TableView';
// import getColumns from '../../../../constants/tableColumnConstants';
import {getResponse} from "../../../../requests/getApi"
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ProductDialog from "../forms/orderComponents/ClassDialog"
import {classGet} from '../../../../utils/apiRequest'
import {
    Grid,
    TextField,
    Divider,
    Button,
    FormControlLabel,
    Checkbox,
    makeStyles,
} from '@material-ui/core';

import styleConstants from '../../../../constants/styleConstants';
import defProduct from "../../../../assets/images/defCategory.png";

//
const useStyles = makeStyles( ( theme ) => ( {
    imageBox: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    image: {
        height: '140px',
        width: '180px',
        marginBottom: '10px',
        border: '1px solid black',
        borderRadius: '5px',
        objectFit: "contain"
    },
    uploadBtn: {
        padding: '4px',
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },

    //
    colorPrimary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary,
    },
} ) );
//

export default function UsersForm ( props ) {
    const {input} = props;
    const classes = useStyles();
    const [isAddModalOpen, setAddModal] = useState(false);
    const [globalClass, setGlobalClass] = useState([]);
    const [parentIdApi, setParentIdApi] = useState({error:'',success:''})
    const [modalLoad, setModalLoad] = useState(false)
    const [parent, setParent] = useState(null)
    const [productCategories, setProductCategories] = useState([]);
    const { t } = useTranslation();
    console.log(props?.disableId)
    React.useEffect(() =>{
        if(parent!==null){
            setModalLoad(true);//
            classGet(parent).then((res) => {
                const productCategories = res.data?.results;
                setProductCategories(productCategories);
                setGlobalClass(productCategories);
                console.log(res.data)
                setModalLoad(false)
            });
        }
    },[parent])
    React.useEffect(() => {
        if(input?.parent && input?.parent!==1){
            getResponse(`/classification/${input?.parent}/`, setParentIdApi)
        }
    },[input?.parent])
    const handleAddIconCLick = () => {
        setParent(1)
        setAddModal(true)
    }
    console.log("--- "+ props?.input?.parent)
    return (
        <>
            <Grid container justify="space-around" alignItems="center">
                <Grid item sm={ 3 } className={ classes.imageBox }>
                    { props.input.photo?.image === undefined ? (
                        <img id="productUpload" className={ classes.image } src={ defProduct } alt="Category Def" />
                    ) : (
                        <img id="productUpload" className={ classes.image } src={ props.input.photo.image } alt="Category" />
                    ) }
                    <Button
                        className={ classes.uploadBtn }
                        variant="contained"
                        color="primary"
                        component="label"
                    >
                        { t( 'categoryProps.upload_photo' ) }
                        <input
                            type="file"
                            hidden
                            accept="image/*"
                            onChange={ props.handleFileChange( 'image' ) }
                        />
                    </Button>
                </Grid>
                <Grid item sm={ 4 }>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="order_in_list"
                        label={ t( 'categoryProps.order_in_list' ) }
                        type="number"
                        value={ props.input[ 'order_in_list' ] || '' }
                        onChange={ props.handleTextChange( 'order_in_list' ) }
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={ 4 }>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="slug"
                        label={ t( 'categoryProps.slug' ) }
                        type="text"
                        value={ props.input[ 'slug' ] || '' }
                        onChange={ props.handleTextChange( 'slug' ) }
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={ clsx( classes.divider, classes.colorPrimary ) } />

            <Grid container justify="space-around">
                <Grid item sm={ 5 }>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="name"
                        label={ t( 'categoryProps.name' ) }
                        type="text"
                        value={ props.input[ 'name' ] || '' }
                        onChange={ props.handleTextChange( 'name' ) }
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={ 5 } className={ classes.rightContainer }>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_ru"
                        label={ t( 'categoryProps.name_ru' ) }
                        type="text"
                        value={ props.input[ 'name_ru' ] || '' }
                        onChange={ props.handleTextChange( 'name_ru' ) }
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_uz"
                        label={ t( 'categoryProps.name_uz' ) }
                        type="text"
                        value={ props.input[ 'name_uz' ] || '' }
                        onChange={ props.handleTextChange( 'name_uz' ) }
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="name_en"
                        label={ t( 'categoryProps.name_en' ) }
                        type="text"
                        value={ props.input[ 'name_en' ] || '' }
                        onChange={ props.handleTextChange( 'name_en' ) }
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={ clsx( classes.divider, classes.colorPrimary ) } />

            <Grid container justify="space-around">
                <Grid item sm={ 5 }>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="short_name"
                        label={ t( 'categoryProps.short_name' ) }
                        type="text"
                        value={ props.input[ 'short_name' ] || '' }
                        onChange={ props.handleTextChange( 'short_name' ) }
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={ 5 } className={ classes.rightContainer }>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="short_name_ru"
                        label={ t( 'categoryProps.short_name_ru' ) }
                        type="text"
                        value={ props.input[ 'short_name_ru' ] || '' }
                        onChange={ props.handleTextChange( 'short_name_ru' ) }
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="short_name_uz"
                        label={ t( 'categoryProps.short_name_uz' ) }
                        type="text"
                        value={ props.input[ 'short_name_uz' ] || '' }
                        onChange={ props.handleTextChange( 'short_name_uz' ) }
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="short_name_en"
                        label={ t( 'categoryProps.short_name_en' ) }
                        type="text"
                        value={ props.input[ 'short_name_en' ] || '' }
                        onChange={ props.handleTextChange( 'short_name_en' ) }
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={ clsx( classes.divider, classes.colorPrimary ) } />
            {/* {props?.table} */ }
            {/* <Table
                rows={data}
                count={count}
                columns={columns}
                waiting={waiting}
                handleFormOpen={setFormOpen}
                reqDelete={categoryDelete}
                refreshPage={() => setRefresh(!refresh)}
                setPagesToFetch={setPagesToFetch}
            /> */}
            {/* <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label={t('categoryProps.description')}
                        type="text"
                        value={props.input['description'] || ''}
                        onChange={props.handleTextChange('description')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_ru"
                        label={t('categoryProps.description_ru')}
                        type="text"
                        value={props.input['description_ru'] || ''}
                        onChange={props.handleTextChange('description_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_uz"
                        label={t('categoryProps.description_uz')}
                        type="text"
                        value={props.input['description_uz'] || ''}
                        onChange={props.handleTextChange('description_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="description_en"
                        label={t('categoryProps.description_en')}
                        type="text"
                        value={props.input['description_en'] || ''}
                        onChange={props.handleTextChange('description_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} /> */}

            {/* <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="attribute_data"
                        label={t('categoryProps.attribute_data')}
                        type="text"
                        value={props.input['attribute_data'] || ''}
                        onChange={props.handleTextChange('attribute_data')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5} className={classes.rightContainer}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="attribute_data_ru"
                        label={t('categoryProps.attribute_data_ru')}
                        type="text"
                        value={props.input['attribute_data_ru'] || ''}
                        onChange={props.handleTextChange('attribute_data_ru')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="attribute_data_uz"
                        label={t('categoryProps.attribute_data_uz')}
                        type="text"
                        value={props.input['attribute_data_uz'] || ''}
                        onChange={props.handleTextChange('attribute_data_uz')}
                        margin="dense"
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="attribute_data_en"
                        label={t('categoryProps.attribute_data_en')}
                        type="text"
                        value={props.input['attribute_data_en'] || ''}
                        onChange={props.handleTextChange('attribute_data_en')}
                        margin="dense"
                    />
                </Grid>
            </Grid> */}
            <Divider className={ clsx( classes.divider, classes.colorPrimary ) } />

            <Grid container justify="space-around">
                <Grid item sm={ 3 }>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="is_active"
                                value={ props.input[ 'is_active' ] || false }
                                color="primary"
                                onChange={ props.handleTextChange( 'is_active' ) }
                            />
                        }
                        label={ t( 'categoryProps.is_active' ) }
                    />
                </Grid>

                <Grid item sm={ 4 }>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="parent"
                        label={ t( 'categoryProps.parent' ) }
                        type="text"
                        value={ props.input[ 'parentName' ] || parentIdApi?.success?.data?.name || '' }
                        onChange={ props.handleTextChange( 'parentName' ) || props.handleTextChange( 'parent' ) }
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={ 1 }>
                    <AddCircleIcon 
                        onClick={handleAddIconCLick} 
                        color="primary" 
                        style={ { fontSize: 40, marginTop: 5, marginLeft: 8, cursor: 'pointer' } } 
                    />
                </Grid>
                <Grid item sm={ 4 }>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="icon"
                        label={ t( 'categoryProps.icon' ) }
                        type="text"
                        value={ props.input[ 'icon' ] || '' }
                        onChange={ props.handleTextChange( 'icon' ) }
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <ProductDialog
                open={ isAddModalOpen }
                setOpen={ setAddModal }
                global={globalClass}
                setParent={setParent}
                parent={parent}
                disableId={props.disableId}
                modalLoad={modalLoad} 
                setModalLoad={setModalLoad}
                setIdParent={props?.setIdParent}
                productCategories={ productCategories }
                setProductCategories={setProductCategories}
            />
        </>
    );
}
