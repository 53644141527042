import React, {useState} from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import { AppBar, Toolbar, IconButton,Button, makeStyles, Typography, Box } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import RoomIcon from '@mui/icons-material/Room';
import clsx from 'clsx';
import Controller from '../views/positions.map'
import UserAvatar from './UserAvatar';
import {useTranslation} from "react-i18next"
import LangSwitcher from './LangSwitcher';
// import Notifications from './Notifications';
import styleConstants from '../../../constants/styleConstants';

//

const drawerWidth = styleConstants.sideBarWidthExpand;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    grow: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    link: {
        textDecoration: 'none',
        marginRight: '20px',
    },
    menuBox: {
        marginLeft: '30px',
        marginRight: '25px',
        display: 'flex',
    },

    // CUSTOMIZATION
    colorPrimary: {
        color: styleConstants.colorPrimary,
    },
    colorBgPrimary: {
        backgroundColor: styleConstants.colorBgPrimary,
    },
}));

export default function Header(props) {
    const {t} = useTranslation();
    const classes = useStyles();
    const { url } = useRouteMatch();
    const [openModal, setOpenModal] = useState(false);
    return (
        <AppBar
            position="fixed"
            className={clsx(classes.appBar, classes.colorBgPrimary, {
                [classes.appBarShift]: !!props.open,
            })}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    onClick={props.handleDrawerToggle}
                    edge="start"
                >
                    <MenuIcon className={classes.colorPrimary} />
                </IconButton>

                <Box className={classes.menuBox}>
                    <Typography variant="h6">
                        <Link className={clsx(classes.link, classes.colorPrimary)} to="/land">
                            Landing
                        </Link>
                    </Typography>
                </Box>

                <div className={classes.grow} />
                <Button
                    color="inherit"
                    aria-label="open drawer"
                    onClick={()=>setOpenModal(!openModal)}
                    edge="start"
                    style={{marginRight:10}}
                >
                    <RoomIcon className={classes.colorPrimary} /> 
                    <span style={{fontSize: '14px', color:"#afbdd1" }}>{t('sidebar.driver')}</span>
                </Button>
                {/* <Notifications style={{ marginRight: '10px' }} /> */}
                <LangSwitcher />
                <UserAvatar url={url} />
                <Controller setOpenModal={setOpenModal} openModal={openModal}/>
            </Toolbar>
        </AppBar>
    );
}
