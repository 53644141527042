import React from 'react';
import { Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Route } from 'react-router-dom';

import Navbar from './components/Navbar';
import ProtectedRoute from '../../components/ProtectedRoute';
import Dashboard from './views/Dashboard';
import Profile from './views/Profile';
import Products from './views/Products';
import Categories from './views/Categories';
import Orders from './views/Orders';
// import HomePage from './components/ReacKanban.jsx';
import Users from './views/Users';
import Customers from './views/Customers';
import Calls from './views/Calls';
import Campaigns from './views/Campaigns';
import Sms from './views/Sms';
import PageNotFound from './views/PageNotFound';
import Survey from "./views/Survey"
import styleConstants from '../../constants/styleConstants';
import mainBg from '../../assets/images/formBg.jpg';
import OrdersHistory from './views/OrdersHistory';
import Modification from "./views/Moderation"
import Classification from "./views/Classifications"
import OrderHistory from './views/OrderHistory';
import Branch from "./views/Branch"
import Socket from './views/notification'
//STYLES
// const drawerWidth = styleConstants.sideBarWidthExpand;
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex'
        // width:"100vw",
        // height: "100vh",
        // background: `linear-gradient(45deg, rgba(253,238,255,0.66) 0%, rgba(239,251,255,0.44) 50%), url(${mainBg}) center center no-repeat`,
        // backgroundSize: "cover",
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end'
    },
    content: {
        minHeight: '100vh',
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        })
        // marginLeft: -drawerWidth,
    },

    //
    colorBg: {
        // background: styleConstants.colorBgAlternative,
        background: 'rgba(82,207,255,0.22)'
        // width:"100vw",
        // height: "100vh",
        // background: `linear-gradient(45deg, rgba(253,238,255,0.66) 0%, rgba(239,251,255,0.44) 50%)`
        // , url(${mainBg}) center center repeat`,
        // backgroundSize: "cover",
    }
}));

//

export default function Root() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Navbar/>
            <main className={clsx(classes.content, classes.colorBg)}>
                <div className={classes.drawerHeader}/>
                <Switch>
                    <ProtectedRoute exact path={`/`}>
                        <Dashboard/>
                    </ProtectedRoute>

                    <ProtectedRoute path={`/products`}>
                        <Products/>
                    </ProtectedRoute>

                    <ProtectedRoute path={`/categories`}>
                        <Categories/>
                    </ProtectedRoute>

                    <ProtectedRoute path={`/orders`}>
                        <Orders/>
                        {/* <HomePage /> */}
                    </ProtectedRoute>

                    <ProtectedRoute exact={true} path={`/history`}>
                        <OrdersHistory/>
                    </ProtectedRoute>
                    <ProtectedRoute exact={true} path={`/history/:id`}>
                        <OrderHistory/>
                    </ProtectedRoute>

                    <ProtectedRoute path={`/users`}>
                        <Users/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`/modification`}>
                        <Modification/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`/classification`}>
                        <Classification/>
                    </ProtectedRoute>

                    <ProtectedRoute path={`/branch`}>
                        <Branch/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`/chat`}>
                        <Socket/>
                    </ProtectedRoute>
                    <ProtectedRoute path={`/notification`}>
                        <Socket/>
                    </ProtectedRoute>

                    {/* <ProtectedRoute path={`/calls`}>
                        <Calls/>
                    </ProtectedRoute> */}

                    {/* <ProtectedRoute path={`/campaign`}>
                        <Campaigns/>
                    </ProtectedRoute> */}

                    {/* <ProtectedRoute path={`/sms`}>
                        <Sms/>
                    </ProtectedRoute> */}

                    <ProtectedRoute path={`/profile`}>
                        <Profile/>
                    </ProtectedRoute>
                    
                    <ProtectedRoute path={`/survey`}>
                        <Survey/>
                    </ProtectedRoute>
                    
                    <Route>
                        <PageNotFound/>
                    </Route>
                </Switch>
            </main>
        </div>
    );
}
