import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { Grid, TextField, Divider, makeStyles } from '@material-ui/core';

import styleConstants from '../../../../constants/styleConstants';

//
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    //
    colorPrimary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary,
    },
}));
//

export default function UsersForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="first_name"
                        label={t('customerProps.first_name')}
                        type="text"
                        value={props.input['first_name'] || ''}
                        onChange={props.handleTextChange('first_name')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="last_name"
                        label={t('customerProps.last_name')}
                        type="text"
                        value={props.input['last_name'] || ''}
                        onChange={props.handleTextChange('last_name')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="phone"
                        label={t('customerProps.phone')}
                        type="tel"
                        value={props.input['phone'] || ''}
                        onChange={props.handleTextChange('phone')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="email"
                        label={t('customerProps.email')}
                        type="email"
                        value={props.input['email'] || ''}
                        onChange={props.handleTextChange('email')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="user"
                        label={t('customerProps.user')}
                        type="number"
                        value={props.input['user'] || ''}
                        onChange={props.handleTextChange('user')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="about"
                        label={t('customerProps.about')}
                        type="text"
                        value={props.input['about'] || ''}
                        onChange={props.handleTextChange('about')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="source"
                        label={t('customerProps.source')}
                        type="text"
                        value={props.input['source'] || ''}
                        onChange={props.handleTextChange('source')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="note"
                        label={t('customerProps.note')}
                        type="text"
                        value={props.input['note'] || ''}
                        onChange={props.handleTextChange('note')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
        </>
    );
}
