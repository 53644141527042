import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import {
    AccountBalanceWallet as WalletIcon,
    Equalizer as EqualizerIcon,
    TrendingUp as TrendingUpIcon,
    PersonAdd as PersonIcon,
    ControlPointDuplicate as OrderIcon,
} from '@material-ui/icons';

import Card from '../components/Card';
import { dashboardGet } from '../../../utils/apiRequest';

//
const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: '20px',
    },
}));

//

export default function Dashboard(props) {
    const classes = useStyles();
    const [data, setData] = useState({});
    const { t } = useTranslation();

    useEffect(() => {
        dashboardGet()
            .then((res) => {
                setData(res.data);
            })
            .catch((err) => {
                console.error(err);
            });
    }, [0]);
    
    return (
        <Grid container className={classes.root} spacing={5}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    color="linear-gradient(90deg, rgba(254,157,24,1) 0%, rgba(252,151,15,1) 100%)"
                    icon={(classN) => <EqualizerIcon className={classN} />}
                    header={t('dashboard.card.monthly_sales')}
                    text={data.monthly_sales}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    color="linear-gradient(90deg, rgba(89,177,93,1) 0%, rgba(81,171,85,1) 100%)"
                    icon={(classN) => <TrendingUpIcon className={classN} />}
                    header={t('dashboard.card.sales')}
                    text={data.sales}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    color="linear-gradient(90deg, rgba(236,74,71,1) 0%, rgba(233,67,63,1) 100%)"
                    icon={(classN) => <WalletIcon className={classN} />}
                    header={t('dashboard.card.total_profit')}
                    text={data.total_profit}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    color="linear-gradient(90deg, rgba(24,189,209,1) 0%, rgba(14,182,202,1) 100%)"
                    icon={(classN) => <PersonIcon className={classN} />}
                    header={t('dashboard.card.new_members')}
                    text={data.new_members}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
                <Card
                    color="linear-gradient(90deg, rgb(111,135,180) 0%, rgb(43,78,145) 100%)"
                    icon={(classN) => <OrderIcon className={classN} />}
                    header={t('dashboard.card.new_orders')}
                    text={data.new_orders ? data.new_orders.length : ''}
                />
            </Grid>
        </Grid>
    );
}
