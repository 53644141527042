import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Table from './TableViewer';
import { TABLE_TYPE } from '../../../constants/tableConstans';
import { callDelete, courierGet, orderHistoryGet, tariffGet } from '../../../utils/apiRequest';
import { courierDialog, userDialog } from '../../../redux/actions/KanbanActions';
import getColumns from '../../../constants/tableColumnConstants';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import styleConstants from '../../../constants/styleConstants';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'red'
            },
            '&:hover fieldset': {
                borderColor: 'yellow'
            },
            '&.Mui-focused fieldset': {
                borderColor: 'green'
            }
        },
        width: '360px',
        // maxWidth: 360,
        backgroundColor: theme.palette.background.paper
    },
    appBar: {
        position: 'relative'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    content: {
        padding: 20
    },
    formControl: {
        width: '100%'
    },
    sectionHeader: {
        color: '#009688',
        fontWeight: 700,
        fontSize: 25
    },
    secondSection: {
        marginTop: 28
    },
    colorPrimary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'left',
        color: theme.palette.text.secondary,
        width: '100%',
        marginTop: '1rem'
    },
    dialogTitle: {
        borderBottom: '1px solid rgba(0,0,0,0.1)',
        display: 'flex!important',
        justifyContent: 'space-between'
    },
    closeButton: {
        position: 'absolute',
        right: '0.5rem',
        top: '0.5rem'
    },
    listItem: {
        cursor: 'pointer',
        transition: '0.3s',
        padding: '0',
        borderRadius: '8px',
        '&:hover': {
            background: 'rgba(0,250,154,0.1)'
        }
    }
}));

const UserDialog = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const userDialogOpen = useSelector((state) => state.kanban.userDialogOpen);
    const userId = useSelector((state) => state.kanban.userId);
    const columns_user = getColumns('userHistory', t);

    useEffect(() => {
        getUsers();
    }, [userDialogOpen]);

    const [refresh, setRefresh] = useState(false);
    const [users, setUsers] = useState([]);
    const [countUsers, setCountUsers] = useState([]);
    const [formOpen, setFormOpen] = useState(false);
    const [pagesToFetchUsers, setPagesToFetchUsers] = useState([1]);
    const [waiting, setWaiting] = useState(false);

    useEffect(() => {
        setWaiting(true);
        const prms = [];
        for (let i of pagesToFetchUsers) prms.push(tariffGet(i));

        Promise.all(prms)
            .then((res) => {
                // console.log(res);
                const obj = [];
                res.forEach((el) => {
                    obj.push(...el.data.results);
                });

                setUsers([...users, ...obj]);
                setCountUsers(res[0].data.count);
            })
            .catch((err) => {
                console.error(err);
                setUsers([]);
            })
            .finally(() => setWaiting(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, pagesToFetchUsers);
    useEffect(() => {
        setUsers([]);
        setPagesToFetchUsers([1]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refresh]);

    const objToSend = {
        id: userId
    };

    const getUsers = () => {
        orderHistoryGet(pagesToFetchUsers, 20, objToSend).then(res => {
            setUsers(res.data.results);
        });
    };

    const handleClose = () => {
        dispatch(userDialog(false));
    };

    return (
        <div>
            <Dialog
                maxWidth={'md'}
                open={userDialogOpen}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.dialogTitle}>
                    <div style={{ fontSize: '1.2rem' }} className={classes.sectionHeader}>
                        {t('userHistoryProps.userHistory')}
                    </div>
                    <IconButton aria-label="close" className={classes.closeButton}
                                onClick={handleClose}>
                        <Close/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Table
                        rows={users}
                        count={countUsers}
                        columns={columns_user}
                        waiting={waiting}
                        type={TABLE_TYPE.user}
                        handleFormOpen={setFormOpen}
                        reqDelete={callDelete}
                        refreshPage={() => setRefresh(!refresh)}
                        setPagesToFetch={setPagesToFetchUsers}
                    />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default UserDialog;