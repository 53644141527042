import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import "./timeline.order.css"

export default function BasicTimeline ( { dataById } ) {
  const status = JSON.parse(localStorage.getItem('status'))
  function checkTypeMessage(data, assignFirstName, assignLastName, old_status, new_status){
    if(data === 'create') return "Buyurtma yaratildi"
    if(data === 'change_status') return `Buyurtma ${status?.results.find(a=>a.id===old_status)?.name} dan ${status?.results.find(a=>a.id===new_status)?.name} ga o'zgartirildi`
    if(data === 'assign') return `Buyurtmaga ${assignFirstName} ${assignLastName} javobgar qilindi`
    if(data === 'received') return " Buyurtma filialga yetkazildi"
    if(data === 'delivered') return "Buyurtma mijozga yetkazildi"
  }
  // console.log( dataById )
  // React.useEffect(()=>{getResponse('/statusorder/', setStatusId)},[])
  // console.log(statusId)
  return (
    <Timeline className="timeline_inner">
      {
        dataById ? dataById?.history?.map( ( item, index ) => (
          <TimelineItem key={index}>
            <TimelineSeparator>
              <TimelineConnector />
                <TimelineDot color="success" variant="outlined" size="large"/>
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent className="t_content" sx={{ py: 2, px: 2 }}>
              <div className="t_content" style={ { display: 'flex', flexDirection: 'column' } }>
                <span className="timeline_items">{item?.date || "00-00-000"}</span>
                <span className="timeline_items">
                  { 
                    item?.event_type === "change_status" ? 
                      checkTypeMessage(item?.event_type, dataById?.assign?.first_name, dataById?.assign?.last_name, item?.old_status, item?.new_status):
                      checkTypeMessage(item?.event_type, dataById?.assign?.first_name, dataById?.assign?.last_name)
                  }
                </span>
                <span className="timeline_items">{ dataById?.user?.username}</span>
              </div>
            </TimelineContent>
          </TimelineItem>
        ) ):<div>Ma'lumot mavjud emas</div>
      }
    </Timeline>
  );
}


// import * as React from 'react';
// import Timeline from '@mui/lab/Timeline';
// import TimelineItem from '@mui/lab/TimelineItem';
// import TimelineSeparator from '@mui/lab/TimelineSeparator';
// import TimelineConnector from '@mui/lab/TimelineConnector';
// import TimelineContent from '@mui/lab/TimelineContent';
// import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
// import TimelineDot from '@mui/lab/TimelineDot';
// import FastfoodIcon from '@mui/icons-material/Fastfood';
// import LaptopMacIcon from '@mui/icons-material/LaptopMac';
// import HotelIcon from '@mui/icons-material/Hotel';
// import RepeatIcon from '@mui/icons-material/Repeat';
// import Typography from '@mui/material/Typography';

// export default function CustomizedTimeline() {
//   return (
//     <Timeline position="alternate">
//       <TimelineItem>
//         <TimelineOppositeContent
//           sx={{ m: 'auto 0' }}
//           align="right"
//           variant="body2"
//           color="text.secondary"
//         >
//           9:30 am
//         </TimelineOppositeContent>
//         <TimelineSeparator>
//           <TimelineConnector />
//           <TimelineDot>
//             <FastfoodIcon />
//           </TimelineDot>
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent sx={{ py: '12px', px: 2 }}>
//           <Typography variant="h6" component="span">
//             Eat
//           </Typography>
//           <Typography>Because you need strength</Typography>
//         </TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineOppositeContent
//           sx={{ m: 'auto 0' }}
//           variant="body2"
//           color="text.secondary"
//         >
//           10:00 am
//         </TimelineOppositeContent>
//         <TimelineSeparator>
//           <TimelineConnector />
//           <TimelineDot color="primary">
//             <LaptopMacIcon />
//           </TimelineDot>
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent sx={{ py: '12px', px: 2 }}>
//           <Typography variant="h6" component="span">
//             Code
//           </Typography>
//           <Typography>Because it&apos;s awesome!</Typography>
//         </TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineConnector />
//           <TimelineDot color="primary" variant="outlined">
//             <HotelIcon />
//           </TimelineDot>
//           <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
//         </TimelineSeparator>
//         <TimelineContent sx={{ py: '12px', px: 2 }}>
//           <Typography variant="h6" component="span">
//             Sleep
//           </Typography>
//           <Typography>Because you need rest</Typography>
//         </TimelineContent>
//       </TimelineItem>
//       <TimelineItem>
//         <TimelineSeparator>
//           <TimelineConnector sx={{ bgcolor: 'secondary.main' }} />
//           <TimelineDot color="secondary">
//             <RepeatIcon />
//           </TimelineDot>
//           <TimelineConnector />
//         </TimelineSeparator>
//         <TimelineContent sx={{ py: '12px', px: 2 }}>
//           <Typography variant="h6" component="span">
//             Repeat
//           </Typography>
//           <Typography>Because this is the life you love!</Typography>
//         </TimelineContent>
//       </TimelineItem>
//     </Timeline>
//   );
// }
