import axios from "axios";
import * as cookies from '../utils/Cookies';

const token = cookies.get('jwt');
const baseUrl = "https://sevimli-lavash.uz/api"

export const getApiFunction = async() => {
    await axios.get('https://sevimli-lavash.uz/api/statusorder/', {
        headers: {
       "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        }})
        .then(response => localStorage.setItem('status',JSON.stringify(response?.data)))
        .catch(err => console.log(err))
}
export const getResponse = async(url, setState) => {
    await axios.get(`${baseUrl}${url}`, {
        headers: {
       "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
        }})
        .then(response => setState({success:response, error:''}))
        .catch(err => setState({success:'',error: err}))
}
export const postResponse = async(url, data, setState) => {
    await axios.post(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success: response, error:''}))
        .catch(err => setState({success:'',error: err}))
}
export const putResponse = async(url, data, setState) => {
    await axios.put(`${baseUrl}${url}`, data, {
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json"
        }})
        .then(response => setState({success: response, error:''}))
        .catch(err => setState({success:'',error: err}))
}