import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import {
    Grid,
    TextField,
    Divider,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    makeStyles,
} from '@material-ui/core';

import { smsPropList as propList } from '../../../../constants/propListConstants';
import styleConstants from '../../../../constants/styleConstants';

//
const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: '100%',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: '20px 0',
    },
    rightContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    sectionHeader: {
        margin: '-20px auto 10px auto',
        textAlign: 'center',
    },

    //
    colorPrimary: {
        color: styleConstants.colorPrimary,
        background: styleConstants.colorBgPrimary,
    },
}));
//

export default function UsersForm(props) {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="phone_number"
                        label={t('smsProps.phone_number')}
                        type="tel"
                        value={props.input['phone_number'] || ''}
                        onChange={props.handleTextChange('phone_number')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="text"
                        label={t('smsProps.text')}
                        type="text"
                        value={props.input['text'] || ''}
                        onChange={props.handleTextChange('text')}
                        margin="dense"
                    />
                </Grid>
            </Grid>
            <Divider className={clsx(classes.divider, classes.colorPrimary)} />

            <Grid container justify="space-around">
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="assign"
                        label={t('smsProps.assign')}
                        type="number"
                        value={props.input['assign'] || ''}
                        onChange={props.handleTextChange('assign')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <TextField
                        fullWidth
                        required
                        variant="outlined"
                        id="user"
                        label={t('smsProps.user')}
                        type="number"
                        value={props.input['user'] || ''}
                        onChange={props.handleTextChange('user')}
                        margin="dense"
                    />
                </Grid>
                <Grid item sm={5}>
                    <FormControl className={classes.formControl}>
                        <InputLabel shrink>{t('callProps.status')}</InputLabel>
                        <Select
                            required
                            value={props.input['status'] || ''}
                            onChange={props.handleTextChange('status')}
                            className={classes.selectEmpty}
                        >
                            {propList[2].enum.map((el) => (
                                <MenuItem value={el} key={el}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
}
