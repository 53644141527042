import React from 'react';
import './assets/css/App.css';
import "bootstrap/dist/css/bootstrap.min.css"
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import {getApiFunction} from "./requests/getApi"
import Login from './pages/login';
import Logout from './pages/logout';
import Root from './pages/root';
import ProtectedRoute from './components/ProtectedRoute';
import ExtendSessionConfirm from './components/ExtendSessionConfirm';

export default function App() {
    React.useEffect(()=>{getApiFunction()},[])
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route exact path="/login">
                        <Login />
                    </Route>

                    <ProtectedRoute exact path="/logout">
                        <Logout />
                    </ProtectedRoute>

                    <ProtectedRoute path="/">
                        <Root />
                    </ProtectedRoute>
                    
                    <ProtectedRoute path="*">
                        <Redirect to="/" />
                    </ProtectedRoute>
                </Switch>
            </Router>

            <ExtendSessionConfirm />
        </div>
    );
}